"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var routes_1 = require("./routes");
var micro_1 = require("@hview/hview-utils/lib/cache/micro");
var user_1 = require("@hview/hview-utils/lib/cache/user");
/**
 * @description 创建微应用路由
 * @return MicroAppRoute[]
 */
var createMicroAppRoute = function () {
    var microAppRoute = [];
    var micros = micro_1.default.getMicroApp() || [];
    if (!micros) {
        console.error("未找到微应用配置");
        return [];
    }
    for (var i = 0; i < micros.length; i++) {
        var micro = micros[i];
        microAppRoute.push({
            path: "".concat(micro.path, "/:path*"),
            name: micro.name,
            component: function () { return Promise.resolve().then(function () { return require("@/components/core/micro-page/index.vue"); }); }
        });
    }
    return microAppRoute;
};
routes_1.default.push.apply(routes_1.default, createMicroAppRoute());
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(),
    routes: routes_1.default
});
router.beforeEach(function (to, from, next) {
    // 获取token，若没有token，代表未登录
    var token = user_1.default.getToken();
    if (!token && to.path != "/login") {
        next("/login");
    }
    if (token && to.path == "/login") {
        next("/");
    }
    next();
});
exports.default = router;
