"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @description 应用启动执行
 */
var micro_app_1 = require("@micro-zoe/micro-app");
var socket_1 = require("@hview/hview-lib/lib/socket");
var packages_1 = require("@hview/hview-vue3/packages");
var AppBoot = /** @class */ (function () {
    function AppBoot() {
    }
    AppBoot.start = function () {
        var socket = window.$scoket;
        if (!socket) {
            socket = new socket_1.default();
            socket.connect();
            // 挂载到window下
            window.$scoket = socket;
            // 监听私聊消息
            packages_1.mitter.on("on-chat-msg", function (data) {
                micro_app_1.default.setGlobalData({ type: "msg", data: data
                });
            });
        }
        else {
            socket.connect();
        }
    };
    return AppBoot;
}());
exports.default = AppBoot;
