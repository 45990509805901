"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//扫描routes文件夹中的ts文件
var modulesFiles = require.context("../routes", true, /\.ts$/);
//定义路由数组
var routes = [];
//循环文件path
modulesFiles.keys().forEach(function (modulePath) {
    //排除index.ts文件
    if (modulePath.indexOf("index.ts") === -1) {
        routes.push.apply(routes, modulesFiles(modulePath).default);
    }
});
exports.default = routes;
