"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "h-menu-item" };
var _hoisted_2 = {
    key: 0,
    class: "menu-group"
};
var _hoisted_3 = { class: "group-title flex align-items__center" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'h-menu-item',
    props: {
        menu: {},
        active: {}
    },
    setup: function (__props) {
        var props = __props;
        var subMenuEvent = function (data) {
            window.history.pushState(null, '', "/".concat(data.app).concat(data.path));
            // 主动触发一次popstate事件
            window.dispatchEvent(new PopStateEvent('popstate', { state: null }));
        };
        return function (_ctx, _cache) {
            var _component_h_menu_item = (0, vue_2.resolveComponent)("h-menu-item", true);
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (_ctx.menu.children && _ctx.menu.children.length > 0)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                        (0, vue_2.createElementVNode)("div", _hoisted_3, [
                            (_ctx.menu.icon)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                    key: 0,
                                    class: (0, vue_2.normalizeClass)(["iconfont m-r__4", _ctx.menu.icon])
                                }, null, 2))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.createElementVNode)("span", {
                                class: (0, vue_2.normalizeClass)(!_ctx.menu.icon ? 'm-l-20' : '')
                            }, (0, vue_2.toDisplayString)(_ctx.menu.text), 3)
                        ]),
                        (0, vue_2.createElementVNode)("div", null, [
                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.menu.children, function (item, index) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_h_menu_item, {
                                    key: index,
                                    active: _ctx.active,
                                    menu: item
                                }, null, 8, ["active", "menu"]));
                            }), 128))
                        ])
                    ]))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                        key: 1,
                        class: (0, vue_2.normalizeClass)(["title flex align-items__center", { 'active': _ctx.menu.path == _ctx.active }]),
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (subMenuEvent(_ctx.menu)); })
                    }, [
                        (_ctx.menu.icon)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                key: 0,
                                class: (0, vue_2.normalizeClass)(["iconfont m-r-6", _ctx.menu.icon])
                            }, null, 2))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.createElementVNode)("span", {
                            class: (0, vue_2.normalizeClass)(!_ctx.menu.icon ? 'm-l-20' : '')
                        }, (0, vue_2.toDisplayString)(_ctx.menu.text), 3)
                    ], 2))
            ]));
        };
    }
});
