"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userLogoutApi = void 0;
/**
 * @description 退出登录
 * @param params
 * @return {Promise<unknown>|Promise<undefined>|*}
 */
var hview_http_1 = require("@hview/hview-http");
var userLogoutApi = function () { return hview_http_1.default.post("/addons/ask/api.user/logout"); };
exports.userLogoutApi = userLogoutApi;
