"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDashboardApi = void 0;
var hview_http_1 = require("@hview/hview-http");
/**
 * @description 获取Dashboard信息
 * @param params
 */
var getDashboardApi = function () { return hview_http_1.default.post("/api/v2/doctor/member/Dashboard"); };
exports.getDashboardApi = getDashboardApi;
