"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElFormValidate = void 0;
/**
 * @description el-form公共表单验证类库
 * @author zhaxianhe
 * @version v1.0
 */
var pattern_1 = require("./pattern");
var ElFormValidate = /** @class */ (function () {
    function ElFormValidate() {
    }
    /**
     * @description 验证手机号码
     * @param rule
     * @param value
     * @param callback
     */
    ElFormValidate.validateMobile = function (rule, value, callback) {
        if (!value) {
            callback(new Error("请输入手机号码"));
        }
        if (!pattern_1.default.MOBILE.test(value)) {
            callback(new Error(rule.message || "请输入正确的手机号码"));
        }
        callback();
    };
    /**
     * @description 验证邮箱
     * @param rule
     * @param value
     * @param callback
     */
    ElFormValidate.validateEmail = function (rule, value, callback) {
        if (!value) {
            callback(new Error("请输入邮箱"));
        }
        if (!pattern_1.default.EMAIL.test(value)) {
            callback(new Error(rule.message || "请输入正确的邮箱"));
        }
        callback();
    };
    return ElFormValidate;
}());
exports.ElFormValidate = ElFormValidate;
