<!--
  @description: 验证码登录
  @author zhaxianhe
  @version v1.0
-->
<template>
  <div class="m-t-10">
    <el-form>
      <el-form-item class="m-b-23" prop="name">
        <el-input class="h-45" placeholder="请输入手机号"/>
      </el-form-item>
      <el-form-item prop="name">
        <el-input class="h-45" placeholder="请输入验证码">
          <template #suffix>
            <el-button type="primary" link>发送验证码</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="w-100p h-45 font-size-16" type="primary">立即登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped>

</style>