"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { key: 0 };
var _hoisted_2 = { key: 1 };
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        modelValue: { default: "HTEXT_DEFAULT_VALUE" }
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", null, [
                (_ctx.modelValue !== 'HTEXT_DEFAULT_VALUE')
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, [
                        (!!_ctx.modelValue || _ctx.modelValue === 0)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_1, (0, vue_2.toDisplayString)(_ctx.modelValue), 1))
                            : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_2, "--"))
                    ], 64))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
