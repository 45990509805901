"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApp = void 0;
var user_1 = require("@hview/hview-utils/lib/cache/user");
var vue_1 = require("vue");
/**
 * @description app应用hooks
 */
function useApp() {
    /**
     * 状态
     */
    var loading = (0, vue_1.ref)(false);
    /**
     * 是否显示
     */
    var visible = (0, vue_1.ref)(false);
    /**
     * @description 设置浏览器标题
     * @param title
     */
    var setTitle = function (meta) {
        if (meta) {
            var title = meta.title;
            document.title = title;
        }
    };
    /**
     * @description 是否需要登录
     * @param toPath
     * @param loginPath
     */
    var needLogin = function (toPath, loginPath) {
        var token = user_1.default.getToken();
        if (!token && toPath != loginPath) {
            return true;
        }
        return false;
    };
    /**
     * @description 用于解决主应用和子应用都是vue-router4时相互冲突，导致点击浏览器返回按钮，路由错误的问题
     * @param router
     */
    var fixBugForVueRouter4 = function (router) {
        // 判断主应用是main-vue3或main-vite，因为这这两个主应用是 vue-router4
        if (window.__MICRO_APP_ENVIRONMENT__) {
            //if (window.location.href.includes('/main-vue3') || window.location.href.includes('/main-vite')) {
            /**
             * 重要说明：
             * 1、这里主应用下发的基础路由为：`/main-xxx/app-vue3`，其中 `/main-xxx` 是主应用的基础路由，需要去掉，我们只取`/app-vue3`，不同项目根据实际情况调整
             *
             * 2、realBaseRoute 的值为 `/app-vue3`
             */
            var realBaseRoute_1 = window.__MICRO_APP_BASE_ROUTE__; //.replace(/^\/app-tenant-[^/]+/g, '')
            router.beforeEach(function () {
                var _a;
                if (typeof ((_a = window.history.state) === null || _a === void 0 ? void 0 : _a.current) === 'string') {
                    window.history.state.current = window.history.state.current.replace(new RegExp(realBaseRoute_1, 'g'), '');
                }
            });
            router.afterEach(function () {
                if (typeof window.history.state === 'object') {
                    window.history.state.current = realBaseRoute_1 + (window.history.state.current || '');
                }
            });
        }
    };
    return {
        setTitle: setTitle,
        needLogin: needLogin,
        fixBugForVueRouter4: fixBugForVueRouter4,
        loading: loading,
        visible: visible
    };
}
exports.useApp = useApp;
