"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    class: "h-editor",
    style: { "border": "1px solid #ccc" }
};
var vue_3 = require("vue");
require("@wangeditor/editor/dist/css/style.css"); // 引入 css
var editor_for_vue_1 = require("@wangeditor/editor-for-vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        modelValue: {},
        editorConfig: { default: { placeholder: '请输入内容...' } },
        toolbarConfig: { default: {} },
        mode: { default: "default" },
        height: { default: 500 }
    },
    emits: ["update:modelValue"],
    setup: function (__props, _a) {
        var __expose = _a.expose, __emit = _a.emit;
        var emits = __emit;
        var toolbarRef = (0, vue_3.ref)();
        var props = __props;
        /**
         * 内容 HTML
         */
        var valueHtml = (0, vue_3.ref)("");
        (0, vue_3.watch)(function () { return props.modelValue; }, function (value) {
            valueHtml.value = value;
        });
        (0, vue_3.watch)(valueHtml, function (value) {
            emits("update:modelValue", value);
        });
        /**
         * 编辑器实例，必须用 shallowRef
         */
        var editorRef = (0, vue_3.shallowRef)();
        /**
         * @description 获取编辑器实例
         */
        var getEditorRef = function () {
            return editorRef.value;
        };
        // 组件销毁时，也及时销毁编辑器
        (0, vue_3.onBeforeUnmount)(function () {
            var editor = editorRef.value;
            if (editor == null)
                return;
            editor.destroy();
        });
        var handleCreated = function (editor) {
            editorRef.value = editor; // 记录 editor 实例，重要！
        };
        __expose({
            getEditorRef: getEditorRef
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)((0, vue_2.unref)(editor_for_vue_1.Toolbar), {
                    style: { "border-bottom": "1px solid #ccc" },
                    ref_key: "toolbarRef",
                    ref: toolbarRef,
                    editor: editorRef.value,
                    defaultConfig: _ctx.toolbarConfig,
                    mode: _ctx.mode
                }, null, 8, ["editor", "defaultConfig", "mode"]),
                (0, vue_2.createVNode)((0, vue_2.unref)(editor_for_vue_1.Editor), {
                    style: (0, vue_2.normalizeStyle)([{ height: _ctx.height + 'px' }, { "overflow-y": "hidden" }]),
                    modelValue: valueHtml.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((valueHtml).value = $event); }),
                    defaultConfig: _ctx.editorConfig,
                    mode: _ctx.mode,
                    onOnCreated: handleCreated
                }, null, 8, ["style", "modelValue", "defaultConfig", "mode"])
            ]));
        };
    }
});
