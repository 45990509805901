"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_1 = require("../cache/user");
// import mainMicroApp from '@micro-zoe/micro-app'
var MicroUtils = /** @class */ (function () {
    function MicroUtils() {
    }
    /**
     * @description 子应用向基座应用发送数据
     * @param data MicroData
     */
    MicroUtils.dispatch = function (data) {
        if (window.__MICRO_APP_ENVIRONMENT__) {
            this.microApp.dispatch(data);
        }
    };
    /**
     * @description 设置全局监听
     * @param data
     */
    MicroUtils.setGlobalData = function (data) {
        if (window.__MICRO_APP_ENVIRONMENT__) {
            this.microApp.setGlobalData(data);
        }
    };
    /**
     * @description 跳转到登录
     */
    MicroUtils.toLogin = function () {
        window.$scoket.disconnect();
        window.$scoket = null;
        user_1.default.clearAll();
        if (window.__MICRO_APP_BASE_APPLICATION__) {
            window.location.href = "/login";
        }
        else {
            this.microApp.router.getBaseAppRouter().replace("/login");
        }
    };
    // 获取microApp实例
    MicroUtils.microApp = window.microApp;
    return MicroUtils;
}());
exports.default = MicroUtils;
