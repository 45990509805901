"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCaptchaApi = exports.sendByCaptchaApi = exports.loginByPasswordApi = void 0;
var hview_http_1 = require("@hview/hview-http");
/**
 * @description 通过密码登录
 * @param params
 */
var loginByPasswordApi = function (params) {
    return hview_http_1.default.post("/api/v2/doctor/login/login", params);
};
exports.loginByPasswordApi = loginByPasswordApi;
/**
 * @description 发送验证码
 * @param params
 */
var sendByCaptchaApi = function (params) {
    return hview_http_1.default.post("/api/v2/doctor/login/login", params);
};
exports.sendByCaptchaApi = sendByCaptchaApi;
/**
 * @description 获取验证码
 */
var getCaptchaApi = function () {
    return hview_http_1.default.post("/api/v2/doctor/login/captcha");
};
exports.getCaptchaApi = getCaptchaApi;
