"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "m-t-10" };
var _hoisted_2 = ["src"];
var vue_3 = require("vue");
var hview_validate_1 = require("@hview/hview-validate");
var login_1 = require("@/api/login");
var login_2 = require("@/components/views/login/src/login");
// 加载状态
exports.default = (0, vue_1.defineComponent)({
    __name: 'login-password',
    setup: function (__props) {
        var loading = (0, vue_3.ref)(false);
        var form = (0, vue_3.ref)();
        // 页面展示的数据
        var uiModel = (0, vue_3.ref)({
            captcha: ""
        });
        // 提交后台数据
        var model = (0, vue_3.ref)({
            account: "",
            password: "",
            captcha: ""
        });
        /**
         * @description 加载图片验证码
         */
        var getCaptchaImage = function () {
            model.value.captcha = "";
            (0, login_1.getCaptchaApi)().then(function (res) {
                uiModel.value.captcha = res.data;
            });
        };
        // 一加载页面初始化一次
        getCaptchaImage();
        /**
         * @description 校验规则
         */
        var rules = (0, vue_3.reactive)({
            account: [
                { required: true, message: "请输入手机号码", trigger: ["blue", "change"] },
                { validator: hview_validate_1.ElFormValidate.validateMobile, trigger: ["blue", "change"] }
            ],
            password: [
                { required: true, message: "请输入密码", trigger: ["blue", "change"] }
            ],
            captcha: [
                { required: true, message: "请输入验证码", trigger: ["blue", "change"] }
            ]
        });
        var submit = function (formEl) {
            formEl.validate(function (valid) {
                if (valid) {
                    loading.value = true;
                    (0, login_1.loginByPasswordApi)(model.value).then(function (res) {
                        login_2.UserLogin.setData(res.data);
                        // 跳转到首页
                        window.history.pushState(null, '', "/");
                        window.dispatchEvent(new PopStateEvent('popstate', { state: null }));
                    }).catch(function () {
                        getCaptchaImage();
                    }).finally(function () {
                        loading.value = false;
                    });
                }
            });
        };
        return function (_ctx, _cache) {
            var _component_el_input = (0, vue_2.resolveComponent)("el-input");
            var _component_el_form_item = (0, vue_2.resolveComponent)("el-form-item");
            var _component_el_button = (0, vue_2.resolveComponent)("el-button");
            var _component_el_form = (0, vue_2.resolveComponent)("el-form");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_el_form, {
                    ref_key: "form",
                    ref: form,
                    rules: rules,
                    model: (0, vue_2.unref)(model)
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_el_form_item, {
                            class: "m-b-23",
                            prop: "account"
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_el_input, {
                                    modelValue: (0, vue_2.unref)(model).account,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (((0, vue_2.unref)(model).account) = $event); }),
                                    class: "h-45",
                                    placeholder: "请输入手机号"
                                }, null, 8, ["modelValue"])
                            ]; }),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_form_item, { prop: "password" }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_el_input, {
                                    modelValue: (0, vue_2.unref)(model).password,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return (((0, vue_2.unref)(model).password) = $event); }),
                                    type: "password",
                                    class: "h-45",
                                    placeholder: "请输入密码"
                                }, null, 8, ["modelValue"])
                            ]; }),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_form_item, { prop: "captcha" }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_el_input, {
                                    modelValue: (0, vue_2.unref)(model).captcha,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return (((0, vue_2.unref)(model).captcha) = $event); }),
                                    class: "h-45",
                                    placeholder: "请输入验证码"
                                }, {
                                    suffix: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createElementVNode)("img", {
                                            class: "cursor-pointer w-130 h-40",
                                            onClick: getCaptchaImage,
                                            src: (0, vue_2.unref)(uiModel).captcha
                                        }, null, 8, _hoisted_2)
                                    ]; }),
                                    _: 1
                                }, 8, ["modelValue"])
                            ]; }),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_form_item, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_el_button, {
                                    class: "w-100p h-45 font-size-16",
                                    type: "primary",
                                    onClick: _cache[3] || (_cache[3] = function ($event) { return (submit(form.value)); }),
                                    loading: (0, vue_2.unref)(loading)
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)("立即登录")
                                    ]; }),
                                    _: 1
                                }, 8, ["loading"])
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }, 8, ["rules", "model"])
            ]));
        };
    }
});
