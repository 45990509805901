"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MicroMessageType = void 0;
/**
 * @description 微前端应用
 */
var storage_1 = require("../storage");
var application_1 = require("@config/application");
var MicroMessageType;
(function (MicroMessageType) {
    // 路由
    MicroMessageType["ROUTE"] = "route";
})(MicroMessageType = exports.MicroMessageType || (exports.MicroMessageType = {}));
var CACHE_KEYS = {
    MICROAPP: "microapp"
};
/**
 * @description 微应用缓存工具
 */
var MicroAppCache = /** @class */ (function () {
    function MicroAppCache() {
    }
    /**
     * @description 设置微应用配置
     * @param data 微应用数据
     */
    MicroAppCache.setMicroApp = function (data) {
        if (!data || data.length == 0) {
            throw new Error("设置微应用数据不能为空!");
        }
        storage_1.localStorage.set(CACHE_KEYS.MICROAPP, data);
        console.log(data);
        console.log(CACHE_KEYS.MICROAPP);
    };
    /**
     * @description 获取微应用配置
     * @return MicroApp[]
     */
    MicroAppCache.getMicroApp = function () {
        // 如果是开发环境，读取本地配置文件，否则读取缓存数据
        if (process.env.NODE_ENV == "development") {
            console.info("当前开发环境，微应用数据正在读取开发配置....");
            console.log(application_1.development.microApps);
            return application_1.development.microApps;
        }
        var data = storage_1.localStorage.get(CACHE_KEYS.MICROAPP);
        if (data && data.length >= 0) {
            return data;
        }
        return null;
    };
    /**
     * @description 删除微应用配置
     */
    MicroAppCache.removeMicroApp = function () {
        storage_1.localStorage.remove(CACHE_KEYS.MICROAPP);
    };
    return MicroAppCache;
}());
exports.default = MicroAppCache;
