"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var storage_1 = require("../storage");
var uuid_1 = require("../tools/uuid");
var CACKE_KEYS = {
    SID: "sid"
};
/**
 * @description sid缓存，此方法因为php端是原始项目，不是前后端分离，所以前端生成
 */
var SidCache = /** @class */ (function () {
    function SidCache() {
    }
    /**
     * @description 设置sid
     * @param sid
     */
    SidCache.setSid = function () {
        if (!this.getSid()) {
            storage_1.localStorage.set(CACKE_KEYS.SID, uuid_1.default.randomUUID());
        }
    };
    /**
     * @description 获取sid
     */
    SidCache.getSid = function () {
        return storage_1.localStorage.get(CACKE_KEYS.SID);
    };
    /**
     * @description 删除sid
     */
    SidCache.removeSid = function () {
        storage_1.localStorage.remove(CACKE_KEYS.SID);
    };
    return SidCache;
}());
exports.default = SidCache;
