"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimeFormat = void 0;
function useTimeFormat() {
    var timeFrom = function (dateTime, format) {
        if (dateTime === void 0) { dateTime = null; }
        if (format === void 0) { format = 'yyyy-mm-dd'; }
        // 如果为null,则格式化当前时间
        if (!dateTime)
            dateTime = Number(new Date());
        // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
        if (dateTime.toString().length == 10)
            dateTime *= 1000;
        var timestamp = +new Date(Number(dateTime));
        var timer = (Number(new Date()) - timestamp) / 1000;
        // 如果小于5分钟,则返回"刚刚",其他以此类推
        var tips = '';
        switch (true) {
            case timer < 300:
                tips = '刚刚';
                break;
            case timer >= 300 && timer < 3600:
                tips = parseInt((timer / 60) + '') + '分钟前';
                break;
            case timer >= 3600 && timer < 86400:
                tips = parseInt((timer / 3600) + '') + '小时前';
                break;
            case timer >= 86400 && timer < 2592000:
                tips = parseInt((timer / 86400) + '') + '天前';
                break;
            default:
                // 如果format为false，则无论什么时间戳，都显示xx之前
                if (format == false) {
                    if (timer >= 2592000 && timer < 365 * 86400) {
                        tips = parseInt((timer / (86400 * 30)) + '') + '个月前';
                    }
                    else {
                        tips = parseInt((timer / (86400 * 365)) + '') + '年前';
                    }
                }
                else {
                    tips = timeFormat(timestamp, format);
                }
        }
        return tips;
    };
    /**
     * @description 日期格式化
     * @param dateTime
     * @param fmt
     */
    var timeFormat = function (dateTime, fmt) {
        if (dateTime === void 0) { dateTime = null; }
        if (fmt === void 0) { fmt = 'yyyy-mm-dd'; }
        // 如果为null,则格式化当前时间
        if (!dateTime)
            dateTime = Number(new Date());
        // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
        if (dateTime.toString().length == 10)
            dateTime *= 1000;
        var date = new Date(dateTime);
        var ret;
        var opt = {
            "y+": date.getFullYear().toString(),
            "m+": (date.getMonth() + 1).toString(),
            "d+": date.getDate().toString(),
            "h+": date.getHours().toString(),
            "M+": date.getMinutes().toString(),
            "s+": date.getSeconds().toString() // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (var k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")));
            }
            ;
        }
        ;
        return fmt;
    };
    return {
        timeFormat: timeFormat,
        timeFrom: timeFrom
    };
}
exports.useTimeFormat = useTimeFormat;
