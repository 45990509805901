"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImage = void 0;
/**
 * @description 图片hooks
 *
 */
var application_1 = require("@config/application");
function useImage() {
    /**
     * @description 获取图片地址
     * @param path
     */
    var getImagePath = function (path) {
        return "".concat((0, application_1.getApiPath)()).concat(path);
    };
    return {
        getImagePath: getImagePath
    };
}
exports.useImage = useImage;
