"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageType = void 0;
var socket_io_client_1 = require("socket.io-client");
var user_1 = require("../../hview-utils/lib/cache/user");
var application_1 = require("@config/application");
var hooks_1 = require("@hview/hview-vue3/packages/hooks");
var packages_1 = require("@hview/hview-vue3/packages");
var element_plus_1 = require("element-plus");
var notify = (0, hooks_1.useMessageNotice)().notify;
(0, hooks_1.useMessageNotice)();
/**
 * @description socket.io
 */
var Socket = /** @class */ (function () {
    function Socket() {
        // socket实例
        this.socketIO = null;
        this.isShowConfirm = false;
    }
    /**
     * @description 创建连接
     */
    Socket.prototype.connect = function () {
        var _this = this;
        var token = user_1.default.getToken();
        if (!token) {
            console.error("当前用户未登录，socketIO无法进行初始化...");
            return;
        }
        if (!this.socketIO) {
            var platform = "pc";
            if (user_1.default.getUserInfo() && user_1.default.getUserInfo().id) {
                platform += '_' + user_1.default.getUserInfo().id;
            }
            this.socketIO = (0, socket_io_client_1.io)((0, application_1.getSocketPath)(), {
                reconnectionDelayMax: 10000,
                auth: {
                    token: token,
                    platform: platform
                },
                transports: ['websocket', 'polling'],
                timeout: 5000
            });
            this.socketIO.on("connect", function () {
                console.log("socketIO已连接...");
            });
            this.socketIO.on("disconnect", function () {
                console.log("连接断开...");
                if (_this.isShowConfirm) {
                    return;
                }
                _this.isShowConfirm = true;
                element_plus_1.ElMessageBox.confirm('为了保证及时收到消息，请重新连接！', '连接中断', {
                    confirmButtonText: '重新连接',
                    cancelButtonText: '暂不连接',
                    type: 'warning',
                }).then(function () {
                    _this.connect();
                }).finally(function () {
                    _this.isShowConfirm = false;
                });
            });
            this.onPrivateMessage();
            this.onGroupMessage();
        }
        else if (!this.socketIO.connected) {
            console.info("...重新连接");
            this.socketIO.connect();
        }
        return this.socketIO;
    };
    /**
     * @description 监听私聊消息
     * @private
     */
    Socket.prototype.onPrivateMessage = function () {
        this.socketIO.on("private msg", function (data) {
            notify(data);
            // 发送全局消息
            packages_1.mitter.emit("on-chat-msg", data);
        });
    };
    /**
     * @description 监听群聊消息
     * @private
     */
    Socket.prototype.onGroupMessage = function () {
        this.socketIO.on("group msg", function (data) {
            // 调用通知
            notify(data);
            // 发送全局通知
            packages_1.mitter.emit("on-chat-msg", data);
        });
    };
    /**
     * @description 获取socketIO连接状态
     * @return {boolean|*}
     */
    Socket.prototype.getSocketStatus = function () {
        if (this.socketIO) {
            return this.socketIO.connected;
        }
        return false;
    };
    /**
     * @description 断开socketIO
     */
    Socket.prototype.disconnect = function () {
        // 判断当前socket对象是否存在，并且当前的socket状态是否为已连接状态
        if (this.socketIO && this.getSocketStatus()) {
            this.socketIO.disconnect();
            this.socketIO = null;
        }
    };
    /**
     * @description 发送私聊消息
     */
    Socket.prototype.sendPrivateMsg = function (data) {
        var _this = this;
        data["category"] = "private";
        // 判断当前的socket状态
        if (!this.getSocketStatus()) {
            this.connect();
        }
        return new Promise((function (resolve, reject) {
            _this.socketIO.emit("private msg", data, function (res) {
                if (res.success) {
                    resolve(data);
                }
                else {
                    reject(false);
                }
            });
        }));
    };
    /**
     * @description 发送群聊消息
     */
    Socket.prototype.sendGroupMsg = function (data) {
        var _this = this;
        data["category"] = "service";
        // 判断当前的socket状态
        if (!this.getSocketStatus()) {
            this.connect();
        }
        return new Promise((function (resolve, reject) {
            _this.socketIO.emit("group msg", data, function (res) {
                if (res.success) {
                    resolve(data);
                }
                else {
                    reject(false);
                }
            });
        }));
    };
    return Socket;
}());
exports.default = Socket;
/**
 * @description 消息类型
 * @type {{IMAGE: string, TEXT: string, AUDIO: string}}
 */
exports.MessageType = {
    TEXT: "text",
    AUDIO: "audio",
    IMAGE: "image",
    ACTION: "action",
    MEDICALQUESTION: "MedicalQuestion",
    SYSTEM: "system",
    CARD: "card" //卡片消息
};
