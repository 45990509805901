"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMessageNotice = void 0;
/**
 * @description 消息通知组件
 */
var user_1 = require("@hview/hview-utils/lib/cache/user");
var element_plus_1 = require("element-plus");
function useMessageNotice() {
    /**
    * @description 判断是否是接收到到消息
    * @param message
    * @return {boolean}
    */
    var isReceive = function (data) {
        return data.from_user_id != user_1.default.getUserInfo().id;
    };
    /**
     * @description 新消息通知
     * @param data
     */
    var notify = function (data) {
        if (user_1.default.getUserInfo().id == data.from_user_id) {
            return;
        }
        var message = "";
        // 文本消息
        if (data.type == "text") {
            message = "<div class=\"w-300 text-ellipsis\">".concat(data.content, "</div>");
        }
        else if (data.type == "image") {
            message = "收到一条图片消息";
        }
        else if (data.type == "card") {
            message = "收到一条卡片消息";
        }
        else if (data.type == "audio") {
            message = "收到一条语音消息";
        }
        if (message) {
            (0, element_plus_1.ElNotification)({
                title: data.from_nickname,
                dangerouslyUseHTMLString: true,
                message: message
            });
        }
    };
    return {
        notify: notify
    };
}
exports.useMessageNotice = useMessageNotice;
