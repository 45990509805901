"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["name", "url", "baseroute"];
var micro_1 = require("@hview/hview-utils/lib/cache/micro");
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    setup: function (__props) {
        var microApps = (0, vue_3.ref)([]);
        var route = (0, vue_router_1.useRoute)();
        (0, vue_3.watch)(route, function () {
            init();
        });
        var init = function () {
            // 从缓存读取微应用配置
            var micros = micro_1.default.getMicroApp();
            if (!micros) {
                throw new Error("未找到微应用相关配置");
            }
            // 获取应用名称
            var appName = 'doctor';
            var microConfig = micros.find(function (item) { return item.name == appName; });
            if (microConfig) {
                microApps.value = [microConfig];
            }
            else {
                throw "未找到相关微应用配置!";
            }
        };
        (0, vue_3.onMounted)(function () {
            init();
        });
        return function (_ctx, _cache) {
            var _component_h_layout = (0, vue_2.resolveComponent)("h-layout");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_h_layout, null, {
                default: (0, vue_2.withCtx)(function () { return [
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(microApps), function (item, index) {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("micro-app", {
                            key: index,
                            name: item.name,
                            url: item.url,
                            baseroute: item.baseRoute || item.path,
                            "router-mode": "native"
                        }, null, 8, _hoisted_1));
                    }), 128))
                ]; }),
                _: 1
            }));
        };
    }
});
