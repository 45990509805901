"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var hview_vue3_1 = require("@hview/hview-vue3");
var home = [{
        path: '',
        component: hview_vue3_1.HLayout,
        children: [{
                path: '',
                meta: {
                    title: "登录"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/home.vue"); }); }
            }]
    }];
exports.default = home;
