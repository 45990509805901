"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HDict = exports.HUpload = exports.HText = exports.HEditor = exports.HPagination = exports.HRouterView = exports.HContainer = exports.HLayout = exports.mitter = void 0;
var h_layout_1 = require("./components/h-layout");
exports.HLayout = h_layout_1.default;
var h_editor_1 = require("./components/h-editor");
exports.HEditor = h_editor_1.default;
var h_text_1 = require("./components/h-text");
exports.HText = h_text_1.default;
var h_dict_1 = require("./components/h-dict");
exports.HDict = h_dict_1.default;
__exportStar(require("./components/h-layout"), exports);
var h_pagination_1 = require("./components/h-pagination");
exports.HPagination = h_pagination_1.default;
var h_container_1 = require("./components/h-container");
exports.HContainer = h_container_1.default;
var h_upload_1 = require("./components/h-upload");
exports.HUpload = h_upload_1.default;
var index_vue_1 = require("./components/h-router-view/index.vue");
exports.HRouterView = index_vue_1.default;
__exportStar(require("./utils/notification"), exports);
__exportStar(require("./hooks"), exports);
var mitter_1 = require("./utils/mitter");
exports.mitter = mitter_1.default;
__exportStar(require("./hooks/time-from"), exports);
var install = function (Vue) {
    for (var key in components) {
        Vue.component(key, components[key]);
    }
};
var components = {
    HLayout: h_layout_1.default,
    HContainer: h_container_1.default,
    HRouterView: index_vue_1.default,
    HPagination: h_pagination_1.default,
    HText: h_text_1.default,
    HUpload: h_upload_1.default,
    HDict: h_dict_1.default
};
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}
exports.default = {
    install: install
};
