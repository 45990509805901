"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login = [{
        path: '/login',
        meta: {
            title: "登录"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/login.vue"); }); }
    }];
exports.default = login;
