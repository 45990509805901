"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @description 正则表达式常量
 */
var Pattern = /** @class */ (function () {
    function Pattern() {
    }
    /**
     * 手机号码
     */
    Pattern.MOBILE = /^1[3456789]\d{9}$/;
    /**
     * 邮箱
     */
    Pattern.EMAIL = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    /**
     * 数字
     */
    Pattern.Number = /^[0-9]*$/;
    return Pattern;
}());
exports.default = Pattern;
