"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserLogin = void 0;
var user_1 = require("@hview/hview-utils/lib/cache/user");
var app_boot_1 = require("@/tools/app-boot");
/**
 * @description 用户登录
 */
var UserLogin = /** @class */ (function () {
    function UserLogin() {
    }
    /**
     * @description 统一设置用户登录的数据
     * @param data
     */
    UserLogin.setData = function (data) {
        var tenants = data.tenants, userinfo = data.userinfo;
        // 设置缓存数据
        user_1.default.setUserInfo(userinfo);
        user_1.default.setTenant(tenants);
        user_1.default.setToken(userinfo.token);
        app_boot_1.default.start();
        // 设置默认租户
        if (tenants.length > 0) {
            var first = tenants[0];
            // 获取租户
            var tenant = first.value;
            var team = first.children[0];
            user_1.default.setDefaultTenant([tenant, team.value]);
        }
    };
    return UserLogin;
}());
exports.UserLogin = UserLogin;
