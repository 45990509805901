"use strict";
/**
 * @description:localStorage
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLength = exports.clear = exports.remove = exports.get = exports.set = void 0;
/**
 * @description:设置localStorage值
 * @param key:键
 * @param value:值
 * @author zhaxianhe
 */
var set = function (key, value) {
    try {
        //判断当前value是否是json
        if (value && typeof value === 'object') {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    }
    catch (e) {
        console.error(e);
    }
};
exports.set = set;
/**
 * @description:获取sessionStorage值
 * @param key:键
 * @returns Object/String
 * @author zhaxianhe
 */
var get = function (key) {
    var str = localStorage.getItem(key);
    try {
        return JSON.parse(str);
    }
    catch (e) {
        return str;
    }
};
exports.get = get;
/**
 * @description:删除sessionStorage中的元素
 * @param key:键
 * @author zhaxianhe
 */
var remove = function (key) {
    localStorage.removeItem(key);
};
exports.remove = remove;
/**
 * @description:清空sessionStorage中的所有元素
 * @author zhaxianhe
 */
var clear = function () {
    localStorage.clear();
};
exports.clear = clear;
/**
 * @description:获取sessionStorage中所有的元素长度
 * @return Number
 * @author zhaxianhe
 */
var getLength = function () {
    return localStorage.length;
};
exports.getLength = getLength;
